@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

// Existing Colors
$light-blue: #4789d2;
$dark-blue: #001831;
$med-blue: #042e5a;
$yellow: #fdb813;
$gray-border: #c1cadb;
$gray-content: #8196ac;
$gray-lightest: #f9fafc;

$gray-darker: #333333;
$gray-dark: #444444;
$gray: #5d779c;
$gray-light: #c1cadb;
$gray-light-update: #d2d2d3;
$gray-lighter: #f7f8fb;

// New Colors
$blue: #0076bb;
$gold: #f3a81d;
$red: #ed2b37;
$navy: #16315a;
$gray-lighter-new: #f8f8f8;

$primary: $blue;
$primary-light: #4d9fcf;
$primary-dark: #16315a;
$secondary: $gold;
$secondary-light: #f6c261;
$secondary-dark: #c28617;
$accent: $red;
$accent-light: #f26b73;
$accent-dark: #bd222c;

@font-face {
  font-family: 'av-icon-font';
  src: url('http://www.avella.com/hubfs/site_fonts/av-icon-font.eot');
  src: url('http://www.avella.com/hubfs/site_fonts/av-icon-font.eot?#iefix') format('embedded-opentype'), url('http://www.avella.com/hubfs/site_fonts/av-icon-font.woff') format('woff'), url('http://www.avella.com/hubfs/site_fonts/av-icon-font.ttf') format('truetype'), url('http://www.avella.com/hubfs/site_fonts/av-icon-font.svg#av-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-family-av-icon: 'av-icon-font', Arial, sans-serif;
$font-family-sans-serif: 'Open Sans', Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
