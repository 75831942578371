.constrain,
%constrain {
  float: none !important;
  margin: auto !important;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1420px;

  [class*='constrain'] {
    padding-left: 0;
    padding-right: 0;
  }

  &--fluid {
    padding-left: 30px;
    padding-right: 30px;
  }

  &--sheet,
  &--12 {}

  &--padded,
  &--10 {
    max-width: 1177px;
  }

  &--narrow,
  &--8 {
    max-width: 934px;
  }

  &--tight,
  &--6 {
    max-width: 690px;
  }

  &--tighter,
  &--4 {
    max-width: 447px;
  }
}

.page {
  &--sheet {
    @extend %constrain;
  }

  &--padded {
    @extend %constrain;

    @extend %constrain--10;
  }

  &--narrow {
    @extend %constrain;

    @extend %constrain--8;
  }

  &--tight {
    @extend %constrain;

    @extend %constrain--6;
  }

  &--tighter {
    @extend %constrain;

    @extend %constrain--4;
  }
}
