.site-header-container {
  body:not([class*='lp']) & {
    min-height: 125px !important;

    @media (min-width: 992px) and (max-width: 1199px) {
      min-height: 75px !important;
    }

    &--submenu-enabled {
      min-height: 155px !important;
    }
  }
}

.site-header {
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 10;

  @media (min-width: 992px) {
    padding: 0;
  }

  a {
    display: block;
    transition: .3s;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-decoratin: none !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  &__main {
    background-color: #fff;
    border-bottom: 1px solid $gray-light;
    position: relative;
    width: 100%;
    z-index: 2;

    > .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
  }

  &__logo {
    padding-left: em(10);
    padding-right: em(10);

    a {
      text-align: center;
    }

    @media (max-width: 991px) {
      border-bottom: 1px solid $gray-light;
      width: 100% !important;

      img, svg {
        margin: em(10) 0;
        max-width: em(170) !important;
      }
    }

    @media (min-width: 992px) {
      padding-left: em(30);
      padding-right: em(30);
    }

    @media (min-width: 1400px) {
      min-width: 16%;
      padding-left: em(50);
      padding-right: em(50);
    }
  }

  &__main-menu {
    text-align: center;
    flex-grow: 1;

    .hs-menu-wrapper > ul {
      display: flex;
      width: 100%;

      > li {
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    @media (max-width: 991px) {
      .hs-menu-depth-1 {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          display: none;
          visibility: hidden;
        }
      }
    }

    .hs-menu-depth-1 {
      display: flex;
      flex-direction: column;
      justify-content: middle;
      align-items: center;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        border-top: 2px solid transparent;
      }

      > a {
        color: $gray-dark;
        padding: em(14) em(10);
        line-height: normal;
        width: 100%;
        border-left: 1px solid $gray-light;
        font-weight: 600;

        @media (max-width: 991px) {
          font-size: em(14);
        }

        @media (min-width: 992px) {
          font-size: em(13);
          padding: em(36) em(10);
        }

        @media (min-width: 1000px) {
          font-size: em(14);
        }

        @media (min-width: 1200px) {
          font-size: em(17);
          padding: em(27.5) em(10);
        }

        &:before {
          font-family: 'av-icon-font';
          color: $yellow !important;
          content: '';
          line-height: normal !important;
          font-weight: normal !important;
          position: relative;
          display: inline-block;
          text-align: center;
          margin: 0 auto;
          margin-right: em(10);
          font-size: em(20);
          vertical-align: middle;

          @media (max-width: 767px) {
            font-size: em(42);
          }

          @media (max-width: 991px) {
            display: block;
            margin-right: 0;
            margin-bottom: 2px;
          }
        }

        &[href*='locations'] {
          &:before {
            content: '\e00e';
          }
        }

        &[href*='specialties'] {
          &:before {
            content: '\e018';
          }
        }

        &[href*='providers'] {
          &:before {
            content: '\e013';
          }
        }

        &[href*='patients'] {
          &:before {
            content: '\e010';
          }
        }

        &[href*='services'] {
          &:before {
            content: '\e017';
          }
        }

        &[href*='about'] {
          &:before {
            content: '\e001';
          }
        }

        &[href*='hospitals'] {
          &:before {
            content: '\f0f8';
            font-family: FontAwesome;
          }
        }
      }

      > .hs-menu-children-wrapper {
        display: none !important;
        position: absolute;
        background-color: $gray-lighter;
        text-align: left;
        top: em(84);
        width: 100%;
        padding: em(30);
        left: 0;
        z-index: 6;
        min-width: 200px !important;

        li {
          display: block;
        }

        a {
          padding: em(5) 0;
        }
      }

      &:nth-child(1) {
        position: static;

        &:hover {
          > .hs-menu-children-wrapper {
            padding: 0;
            display: flex !important;
            flex-wrap: wrap;

            > li {
              > a {
                display: none;
              }

              &:nth-child(1) {
                width: 60%;
                padding: em(40);

                > .hs-menu-children-wrapper {
                  display: flex;
                  flex-wrap: wrap;

                  > li {
                    width: 33.3%;
                  }
                }
              }

              &:nth-child(2) {
                background-color: darken($gray-lighter, 5%);
                width: 40%;
                padding: em(40);
              }
            }
          }
        }
      }

      &:nth-child(2) {
        position: static;

        &:hover {
          > .hs-menu-children-wrapper {
            display: flex !important;
            flex-wrap: wrap;

            > li {
              width: 25%;
            }
          }
        }
      }

      &:nth-child(4) {
        > a {
          @media (max-width: 991px) {
            border-right: 1px solid $gray-light;
          }
        }
      }

      &:hover {
        border-color: $gray-lighter;

        > li {}

        &, & > a {
          background-color: $gray-lighter;
        }

        > .hs-menu-children-wrapper {
          display: block !important;
        }
      }
    }

    .hs-menu-depth-2 {
      > a {
        color: $med-blue;
        font-size: em(15);
        padding: em(2) 0;
        line-height: normal;

        &:hover {
          color: $light-blue !important;
        }
      }
    }

    .hs-menu-depth-3 {
      > a {
        color: $med-blue;
        font-size: em(15);

        &:hover {
          color: $light-blue !important;
        }
      }
    }

    .hs-menu-children-wrapper {
      @media (max-width: 991px) {
        display: none !important;
        visibility: hidden !important;
      }
    }
  }

  &__secondary {
    background-color: $med-blue;

    > .inner {
      padding-left: em(25);
      padding-right: em(25);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media (max-width: 992px) {
      display: none;
      visibility: hidden;
    }
  }

  &__secondary-menu {
    padding-top: em(11.5);
    padding-bottom: em(11.5);

    @media (max-width: 479px) {
      display: none;
    }

    .hs-menu-wrapper {
      > ul {
        display: flex;
      }
    }

    .hs-menu-depth-1 {
      > a {
        color: $gray-light;
        padding: 0 em(13);
        font-size: em(16);
        line-height: normal;
        font-weight: 600;

        &:hover {
          color: #fff;
        }
      }

      &:nth-child(3) {
        border-left: 2px solid rgba($gray, 0.4);
        padding-left: em(15);
        margin-left: em(15);
      }

      &:nth-child(3),
      &:nth-child(4) {
        a {
          color: #fff;
        }
      }
    }
  }

  &__logins {
    * {
      color: #a3bedc !important;
    }

    .hs-menu-depth-1 {
      > a {
        font-size: em(15);
        text-transform: uppercase;
        font-weight: 600;

        &:before {
          display: inline-block;
          content: url(//cdn2.hubspot.net/hub/221788/file-671984393-png/site_images/icon-lock.png?t=1513806909073);
          margin-right: em(8);
        }
      }
    }
  }

  &--scroll {
    .site-header__main {
      position: fixed;
      top: 0;
    }
  }

  &__submenu {
    text-align: center;
    z-index: 0;

    &__trigger {
      background: none;
      border: none;
      display: inline-block;
      padding: em(20) 0;
      text-align: center;
      font-weight: 600;
      color: $dark-blue !important;
      position: relative !important;
      font-size: em(18);

      .label {}

      .icon {
        position: relative;
        display: inline-block;

        &:after {
          content: '\f078';
          display: block;
          font-family: FontAwesome;
          -webkit-font-smoothing: antialised;
          font-size: 10px;
          margin-left: em(10);
        }
      }

      .site-header__submenu.active & {
        .icon {
          &:after {
            transform: scaleY(-1);
          }
        }
      }

      @media (min-width: 768px) {
        display: none !important;
      }
    }

    .hs-menu-wrapper {
      @media (max-width: 767px) {
        padding-bottom: em(20);
      }
    }

    ul {
      text-align: left;
      margin: 0;
    }

    &:not(.active) {
      @media (max-width: 767px) {
        .hs-menu-wrapper {
          display: none;
        }
      }
    }

    &:not(.enabled) {
      display: none;
      visibility: hidden;
    }
  }
}

.hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1:nth-child(1) .hs-menu-children-wrapper, .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1:nth-child(2) .hs-menu-children-wrapper {
  @media (max-width: 767px) {
    display: none !important;
    visibility: hidden !important;
  }
}
