// Old Styles / Remove when new footer is added to all templates

.logo-row {
  background-color: #fff;
}

%headline {
  line-height: (34 / 26);
  font-weight: 700;
  display: block;
  color: #fff;
  text-transform: uppercase;
  font-size: em(16);
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: em(15);
}

.site-footer {
  background-color: #072e5b;
  text-align: left;
  background-size: 50% auto;

  .lp & {
    background-image: none;
  }

  > .inner {
    padding: em(25) em(30);
    padding-top: em(25);
    padding-bottom: em(25);
    position: relative;

    @media (min-width: 768px) {
      padding: em(25) em(60);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .lp & {
        display: block;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  a {
    display: block;
    text-decoration: none;
  }

  &__logos {
    background-color: #fff;
    border-top: 1px solid $gray-light;
    padding-top: em(40);
    padding-bottom: em(40);

    > .inner {
      padding-left: em(30);
      padding-right: em(30);

      @media (min-width: 768px) {
        padding-left: em(60);
        padding-right: em(60);
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      width: 100%;
      justify-content: space-between;

      > li {
        padding: em(10);
        padding-top: 0;

        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }

      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;

        > li {
          padding: em(20);
          padding-top: 0;
        }
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__main {
    .hs-menu-depth-1 {
      > a {
        color: $gray-light;
        padding: em(6) 0;

        &:hover {
          color: #fff;
        }
      }
    }

    @media (min-width: 768px) {
      .lp & {
        width: 100%;
      }
    }
  }

  &__main-menu {
    margin-bottom: em(30);
    display: none;

    &:before {
      @extend %headline;

      content: 'Specialties';
    }

    .hs-menu-wrapper {
      > ul {
        display: flex;
        flex-direction: column;
        max-height: em(250);
        flex-wrap: wrap;
      }
    }

    @media (max-width: 991px) {
      display: none;
      visibility: hidden;
    }
  }

  &__bottom-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: em(14);

    * {
      color: $gray-light;
    }

    footer {
      opacity: .8;
    }
  }

  &__secondary {
    @media (min-width: 768px) {
      .lp & {
        width: 100%;
      }
    }
  }

  &__secondary-menu {
    margin-right: em(25);

    .hs-menu-depth-1 {
      margin-right: em(10);
    }
  }

  &__social-icons {
    margin-top: em(40);

    ul {
      margin: 0;
      padding: 0;
      line-height: 0;
    }

    a {
      width: 34px;
      height: 34px;

      &:before {
        font-size: 16px !important;
      }
    }

    &:before {
      @extend %headline;

      content: 'Connect with Us';
      display: none;
    }

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__copyright {}
}
