.submenu {
  @extend %module !optional;

  background-color: #f6f6f6;
  position: relative;
  text-align: left;
  padding-top: em(15) !important;
  padding-bottom: em(15) !important;

  @media (max-width: 767px) {
    font-size: em(18);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: top;

      @media (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }
  }

  a {
    display: block;
    padding: em(10) !important;
  }

  &--static {}

  &--dynamic {
    .hs-menu-depth-1 {
      &:not(.active-branch) {
        display: none;
        visibility: hidden;
      }

      &.active-branch {
        width: 100%;

        > a {
          display: none;
        }

        > .hs-menu-children-wrapper {
          display: flex !important;
          justify-content: center !important;
          flex-wrap: wrap !important;
        }

        .hs-menu-children-wrapper {
          opacity: 1 !important;
          visibility: visible !important;
          position: relative !important;
          width: 100% !important;
        }

        @media (min-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center !important;
          flex-wrap: wrap;
          text-align: center !important;

          > li {}
        }

        > ul {
          display: block;
        }
      }
    }

    .hs-menu-depth-2 {
      @media (min-width: 768px) {
        padding: 0 em(20);
      }

      > a {
        color: $dark-blue;
        font-weight: 600;
        padding: em(15);

        &:hover {
          color: $gold;
        }
      }

      &:not(.active-branch) {
        display: none;
      }

      &.active-branch {
        > a {
          display: none !important;
        }

        .hs-menu-children-wrapper {
          display: block !important;
          top: auto !important;
          left: auto !important;
          position: relative !important;
        }

        > .hs-menu-children-wrapper {
          left: 0 !important;

          @media (min-width: 768px) {
            display: flex !important;
            justify-content: center !important;
            flex-wrap: wrap !important;
          }
        }
      }
    }

    .hs-menu-depth-3 {
      display: block !important;

      @media (min-width: 768px) {
        padding: 0 em(20);
      }

      > a {
        color: $dark-blue;
        font-weight: 600;
        padding: em(15);

        &:hover {
          color: $gold;
        }
      }
    }

    .hs-menu-depth-4 {
      display: none;
    }
  }

  .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li.hs-item-has-children ul.hs-menu-children-wrapper {
    left: 0 !important;
  }
}
