$menu-width: 240px;

body.menu-open {
  position: relative;
  left: -$menu-width;
}

.menu-trigger__container {
  cursor: pointer;
  text-align: center;
  justify-content: center;
  padding: em(18) em(20);

  button > span {
    align-items: center;

    svg {
      width: 20px;
    }
  }

  @media (min-width: 992px) {
    display: none;
    visibility: hidden;
  }
}

.menu-trigger__button {
  outline: none;
  border: 0;
  background: none;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;

  > .inner {
    display: block;
  }

  span.label {
    display: block;
    clear: both;
    color: $gray-dark;
    font-size: 11px;
    padding-top: 12px;
    font-size: em(14);
    letter-spacing: 0;
    font-weight: 600;

    &:after {
      content: 'More';
    }

    .open & {
      &:after {
        content: 'Close';
      }
    }
  }

  span.icon {
    display: block;
    width: 18px !important;
    margin-left: auto;
    margin-right: auto;

    @include burger(18px, 2px, 3px, $primary, 3px, 0.3s);

    .open & {
      @include burger-to-cross;
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  transition: .3s;
  z-index: 9;
  background-color: $med-blue;
  width: $menu-width;
  height: 100%;
  overflow: scroll;
  text-align: left;

  > .inner {
    padding-bottom: 20px;
  }

  * {
    color: $gray-light;
  }

  a {
    display: block;
    text-decoration: none;
    transition: .3s;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__logins {
    font-size: 16px;

    .hs-menu-depth-1 {
      > a {
        text-transform: uppercase;
        padding: 20px;
        color: #a3bedc;

        &:before {
          display: inline-block;
          content: url(//cdn2.hubspot.net/hub/221788/file-671984393-png/site_images/icon-lock.png?t=1513806909073);
          margin-right: em(8);
        }
      }
    }
  }

  &__main {
    .hs-menu-depth-1 {
      > a {
        padding: 7px 25px;
        font-size: 27px;
        color: #fff;
        border-top: 1px solid #0b468a;
      }

      &:not(:nth-child(2)) {
        > ul {
          display: none;
        }
      }
    }

    .hs-menu-depth-2 {
      > a {
        padding: 2px 25px;
        font-size: 16px;
        color: #a3bedc;
        font-weight: 400;
      }
    }
  }

  &__secondary {
    .hs-menu-wrapper > ul {
      display: flex;
      flex-direction: column-reverse;
    }

    .hs-menu-depth-1 {
      > a {
        padding: 9px 25px;
        font-size: 24px;
        color: #fff;
        border-top: 1px solid #0b468a;
      }
    }
  }

  &:not(.open) {
    display: none;
    visibility: hidden;
  }

  &__close-btn {
    position: relative;
  }
}
