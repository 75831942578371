// Dependant on global.wrapIcons function
.social-link {
  display: inline-block;

  &__icon {
    display: block;
    position: relative;
    margin: em(5) em(4);
    background-color: #fff;
    padding: 0;
    width: 42px;
    height: 42px;
    font-weight: normal !important;
    padding: 0 !important;

    span {
      display: none;
    }

    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: .3s;
      z-index: 1;
      color: inherit;
      font-family: FontAwesome;
      font-size: em(22) !important;
      -webkit-font-smoothing: antialiased;
      color: #fff;
    }

    &[href*='facebook'] {
      background-color: #3b5998;

      &:before {
        content: '\f09a';
      }
    }

    &[href*='twitter'] {
      background-color: #55acee;

      &:before {
        content: '\f099';
      }
    }

    &[href*='linkedin'] {
      background-color: #0077b5;

      &:before {
        content: '\f0e1';
      }
    }

    &[href*='plus.google'] {
      background-color: #dc4e41;

      &:before {
        content: '\f1a0';
      }
    }

    &[href*='instagram'] {
      &:before {
        content: '\f16d';
      }
    }

    &[href*='youtube'] {
      background-color: #d34836;

      &:before {
        content: '\f16a';
      }
    }

    &[href*='mailto'] {
      &:before {
        content: '\f003';
      }
    }

    &:hover {
      &,
      &:before {
        color: #fff;
      }
    }
  }
}
