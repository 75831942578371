body.lp {
  .site-header {
    padding: em(30) 0;

    > .inner {
      justify-content: center;
    }

    &__main-menu,
    &__secondary-menu,
    .menu-trigger__container,
    .mobile-menu {
      display: none;
    }
  }

  .site-footer {
    &__logos,
    &__social-icons,
    &__main-menu,
    &__secondary-menu {
      display: none;
    }

    &__bottom-nav {
      justify-content: center;
    }
  }
}

.lp {
  &--no-header {
    .site-header {
      display: none;
      visibility: hidden;
    }

    .body-container-wrapper {
      border: none !important;
    }
  }
}
